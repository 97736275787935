import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, containerless, LogManager } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { Models } from 'models/core';
import { ConnectionService, ConnectionTypeService, CouplingService } from 'services';
import { ErrorService } from 'services/error-service';
import { LoopPositionHorizontalService } from 'services/loop-position-horizontal-service';
import { LoopPositionVerticalService } from 'services/loop-position-vertical-service';
import { LoopService } from 'services/loop-service';
import { LoopTypeService } from 'services/loop-type-service';
import { ToastService } from 'services/toast-service';
import { LoopFormInlineBase } from './loop-form-inline.base';

const logger: Logger = LogManager.getLogger('loop-form-inline-new');

@autoinject
@containerless
export class LoopFormInlineNew extends LoopFormInlineBase {
  @bindable private netId: number;

  protected looppositionsvertical: Array<Models.LoopPositionVertical>;
  protected looppositionshorizontal: Array<Models.LoopPositionHorizontal>;

  constructor(
    loopService: LoopService,
    connectionService: ConnectionService,
    connectionTypeService: ConnectionTypeService,
    couplingService: CouplingService,
    loopPositionHorizontalService: LoopPositionHorizontalService,
    loopTypeService: LoopTypeService,
    eventAggregator: EventAggregator,
    toastService: ToastService,
    errorService: ErrorService,
    loopPositionVerticalService: LoopPositionVerticalService
  ) {
    super(
      loopService,
      connectionService,
      connectionTypeService,
      couplingService,
      loopPositionHorizontalService,
      loopTypeService,
      eventAggregator,
      toastService,
      errorService,
      loopPositionVerticalService
    );

    this.loop = new Models.Loop();
  }

  attached() {
    if (this.centerBottom) {
      logger.debug('set LoopPositionHorizontalId = 6');
      this.loop.LoopPositionHorizontalId = 6;
    }
  }

  protected async getLoopTypes() {
    const res = await this.loopTypeService.getAllCached();
    return res.filter((x) => x.NavisionProductId || x.DeltaProductId || x.IsDeleted);
  }

  protected async getLoopPositionsHorizontal() {
    const res = await this.loopPositionHorizontalService.getAllCached();
    if (!this.centerBottom) {
      return res.filter((x) => x.Id !== 7 && x.Id !== 8 && x.Id !== 12);
    }
    return res.filter((x_1) => x_1.Id === 6);
  }

  protected async createLoop() {
    try {
      this.loop.NetId = this.netId;
      const loop = await this.loopService.post(this.loop);
      this.eventAggregator.publish('loopListReset', 1);
      this.eventAggregator.publish('loopFormNewClose', 1);
      this.toastService.showSuccess('loop.created');

      if (this.is2021Standard) {
        this.loop.Id = loop.Id;
        await this.setConnections();
      }

      this.loop = new Models.Loop();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  public cancel() {
    this.eventAggregator.publish('loopFormNewClose', 1);
  }
}
