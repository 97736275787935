import { EventAggregator } from 'aurelia-event-aggregator';
import { containerless } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { ConnectionService, ConnectionTypeService, CouplingService } from 'services';
import { ErrorService } from 'services/error-service';
import { LoopPositionHorizontalService } from 'services/loop-position-horizontal-service';
import { LoopPositionVerticalService } from 'services/loop-position-vertical-service';
import { LoopService } from 'services/loop-service';
import { LoopTypeService } from 'services/loop-type-service';
import { ToastService } from 'services/toast-service';
import { LoopFormInlineBase } from './loop-form-inline.base';

@containerless
@autoinject
export class LoopFormInlineEdit extends LoopFormInlineBase {
  protected looppositionsvertical: Array<Models.LoopPositionVertical>;
  protected looppositionshorizontal: Array<Models.LoopPositionHorizontal>;

  constructor(
    loopService: LoopService,
    connectionService: ConnectionService,
    connectionTypeService: ConnectionTypeService,
    couplingService: CouplingService,
    loopPositionHorizontalService: LoopPositionHorizontalService,
    loopTypeService: LoopTypeService,
    eventAggregator: EventAggregator,
    toastService: ToastService,
    errorService: ErrorService,
    loopPositionVerticalService: LoopPositionVerticalService
  ) {
    super(
      loopService,
      connectionService,
      connectionTypeService,
      couplingService,
      loopPositionHorizontalService,
      loopTypeService,
      eventAggregator,
      toastService,
      errorService,
      loopPositionVerticalService
    );
  }

  protected async attached() {
    await this.getCouplings();
    this.selectedCouplingId = this.loop.Connections?.find((c) => Number.isInteger(+c.CouplingId))?.CouplingId;
  }

  protected async getLoopTypes() {
    const res = await this.loopTypeService.getAllCached();
    return res.filter((x) => x.NavisionProductId || x.DeltaProductId || !x.IsDeleted);
  }

  protected async getLoopPositionsHorizontal() {
    const res = await this.loopPositionHorizontalService.getAllCached();
    if (this.centerBottom == undefined) {
      return res;
    }
    return this.centerBottom ? res.filter((x) => x.Id === 6) : res.filter((x_1) => x_1.Id !== 6);
  }

  public async updateLoop() {
    if (this.is2021Standard) {
      await this.setConnections();
    }

    this.loop.LoopPositionVertical = null;
    this.loop.LoopPositionHorizontal = null;
    this.loop.LoopType = null;

    if (this.hasConnectionChecked()) {
      this.loop.LoopTypeId = null;
    }

    this.loop.Connections = [];

    this.loopService
      .put(this.loop, this.loop.Id)
      .then(() => {
        this.eventAggregator.publish('loopListReset', 1);
        this.eventAggregator.publish('loopFormEditClose', 1);
        this.toastService.showSuccess('loop.updated');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  public async deleteLoop() {
    for (const connection of this.loop.Connections) {
      await this.deleteConnection(connection.Id);
    }
    this.loopService
      .delete(this.loop.Id)
      .then(() => {
        this.eventAggregator.publish('loopFormEditClose', 1);
        this.eventAggregator.publish('loopListReset', 1);
        this.toastService.showSuccess('loop.deleted');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  public cancelEdit() {
    this.eventAggregator.publish('loopFormEditClose', 1);
    this.eventAggregator.publish('loopListReset', 1);
  }
}
